.promo-card {
  height: 100vh;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
}

.promo-banner .banner-image {
  width: -100%;
  height: auto;
}

.promo-content {
  padding: 16px;
  text-align: center;
}

.promo-content h4 {
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

.promo-content h2 {
  font-size: 20px;
  margin: 10px 0;
  color: #222;
}

.promo-content p {
  font-size: 14px;
  color: #444;
  margin-bottom: 10px;
}

.small-text {
  font-size: 12px;
  color: gray;
}

.promo-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ddd;
  padding: 10px;
}

.default-app-logo {
  width: 45px;
  height: 45px;
  /* border-radius: 8px;
  margin-right: 10px; */
}

.default-get-app-button {
  flex-grow: 1;
  background: black;
  color: white;
  border: none;
  padding: 12px;
  margin: 1rem;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.default-get-app-button:hover {
  background: #333;
}

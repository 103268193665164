.leaderboard-container{
    height: 100vh;
    background-image: url("../Assets/contour-line.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.avatar{
    transform: translateY(-10px);
}

.badge{
    transform: translate(30px, -25px);
}

.crown{
    /* transform: translate(-10px, 0px); */
    position: relative;
    z-index: 2;
    /* transform: rotate(10deg); */
}

.leaderboard-card {
    border-radius: 45px 45px 0px 0px !important;
}

.player-card {
    box-shadow: 0 6px 10px 0 rgba(0, 204, 187, 0.19)!important;
}
/* nav{
  display: none;
} */
.web-fallback-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 20px;
  /* background: #f5f5f5; */
  border-radius: 10px;
  overflow-y: hidden;
  height: 100vh;
}

.card {
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.3);
  text-align: center;
  height: 75vh;
  position: relative;
  border: 2px solid #ddd; /* Optional border */
}

/* Small notch or speaker area at the top */
.card::before {
  content: "";
  width: 50px;
  height: 8px;
  background: #bbb;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.app-preview{
  position: relative;
}

.iphone-placeholder {
  /* box-shadow: 0 0 8px 10px #eee; */
  height: 80vh;
}

.app-logo {
  position: absolute;
  /* margin-top: 6rem; */
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
  border-radius: 10px;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.web-fallback-app-name {
  position: absolute;
  font-size: 14px;
  color: gray;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.meta-info{
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center; /* Ensures equal spacing */
  align-items: center; /* Align items vertically */
  padding: 0 20px; /* Add some padding to prevent edge sticking */
}

.meta-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1rem;
  text-align: center;
}

.meta-desc {
  font-size: 12px;
  text-align: justify;
}

.download-section {
  text-align: center;
  width: 40vw;
}

.download-section h1 {
  font-size: 30px;
}

.qr-code {
  width: auto;
  height: 25vh;
  margin: 10px 0;
}

.or-text {
  font-size: 1rem;
  color: gray;
}

.store-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 1rem;
}

.store-btn {
  height: auto;
  cursor: pointer;
  width: 80%;
}

.store-btn:hover {
  transform: translateY(-10px);
  transition: transform 0.3s ease;
}

@media screen and (max-width: 768px) {
  .web-fallback-container {
    flex-direction: column;
    gap: 20px;
  }
    
}

@media screen and (min-width: 1200px) {
  .app-logo{
    width: 120px;
    height: 120px;
  }

  .meta-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .meta-desc {
    font-size: 1rem;
  }
    
}

@media screen and (min-width: 1600px) {
  .download-section h1 {
  font-size: 40px;
  margin-bottom: 1rem;
}

.app-logo {
  width: 120px;
  height: 120px;
}

.meta-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.meta-desc {
  font-size: 1rem;
}
    
}

@media screen and (min-width: 2000px) {
  .download-section h1 {
  font-size: 60px;
  margin-bottom: 1rem;
}

.app-logo {
  width: 200px;
  height: 200px;
}

.or-text {
  font-size: 2rem;
}

.meta-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.meta-desc {
  font-size: 1.8rem;
}
    
}
.section {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('../Assets/hero-bg.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.home-section-img{
  position: fixed;
  top: 150px;
  right: 120px;
}

@media only screen and (max-width: 650px) {
  .section{
    background-color: #fff;
    background-image: none;
  }
}
@media only screen and (max-width: 800px) {
  .section{
    background-color: #fff;
    background-image: none;
  }}
@media only screen and (min-width: 1500px) {
  .home-section-img {
    top: 180px;
}
}


/* nav {
    display: none;
} */
/* Container */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 40px 20px;
  text-align: center;
}

/* App Icon */
.app-icon img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

/* App Name & Title */
.app-name {
  color: #4b5563;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.app-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
}

/* Copy Section */
.copy-section {
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  width: 90%;
  max-width: 350px;
}

.copy-text,
.redirect-text {
  color: #6b7280;
  font-size: 14px;
}

.app-store-icon img {
  width: 40px;
  height: 100px;
  margin-top: 10px;
}

/* Powered by Branch */
.powered-by {
  color: #9ca3af;
  font-size: 12px;
  margin-top: 15px;
}

/* Buttons */
.get-app-button {
  /* background-color: #2563eb; */
  /* color: white; */
  font-size: 16px;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  margin: 1rem;
  cursor: pointer;
  width: 90%;
  max-width: 350px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.get-app-button:hover {
  background-color: #009185;
}

.get-app-link {
  color: #009185;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 0;
}

.get-app-button svg {
  margin: 0 10px;
}
